<template>
  <b-container>
    <transition name="slide">
      <b-row v-if="isVisible">
        <b-col class="p-3">
          <div class="title-text">
            Precisamos saber um pouco mais sobre seu ciclocomputador
          </div>
        </b-col>
      </b-row>
    </transition>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <transition name="slide">
        <form v-if="isVisible">
          <b-row class="mb-3">
            <b-col md="2">
              <ValidationProvider
                rules="required"
                name="Marca"
                v-slot="{ errors }"
                vid="model"
              >
                <CustomTextInput
                  v-model="fields.brand"
                  :model="fields.brand"
                  :errors="errors"
                  :placeholder="'Marca'"
                  type="text"
                />
                <span v-if="showErrors.model && fields.brand === ''" class="error-msg"
                  >Obrigatório</span
                >
              </ValidationProvider>
            </b-col>

            <b-col md="2">
              <div class="title-description">Valor do acessório (sem os centavos):</div>
              <ValidationProvider
                name="valor do acessório"
                mode="passive"
                :rules="'required|valor_acessorio'"
                v-slot="{ errors }"
              >
                <CustomTextInput
                  v-model="fields.value"
                  :model="fields.value"
                  v-mask="getCurrencyMask(fields.value)"
                  :placeholder="'R$'"
                  :errors="errors"
                  type="tel"
                />
                <span class="error-msg" v-if="showErrors.value">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>
          
          <div class="title-ask">Possui nota fiscal do acessório?</div>
          <div class="row-btn">
            <button
              :class="['btn', { selected: haveNF === true }]"
              @click="haveNF = true"
              type="button"
            >
              Sim, possuo nota fiscal
            </button>
            <button
              :class="['btn', { selected: haveNF === false }]"
              @click="haveNF = false"
              type="button"
            >
              Não possuo nota fiscal
            </button>
          </div>
          <div v-if="haveNF === false" class="box-info">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                class="info-icon"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              >
                <circle cx="12" cy="12" r="9"></circle>
                <line x1="12" y1="8" x2="12" y2="9"></line>
                <line x1="12" y1="11" x2="12" y2="17"></line>
              </svg>
            </div>
            <div class="row-text">
              <p>
                <span class="attention">{{ "Atenção:" }}</span> {{ message }}
              </p>
            </div>
          </div>
        </form>
      </transition>
      <button id="footerButton" class="footer-button-back" @click="previousStep">
        Voltar
      </button>
      <button
        id="footerButton"
        class="footer-button"
        @click="handleClick"
        :disabled="invalid || haveNF !== true"
      >
        Avançar
      </button>
    </ValidationObserver>
  </b-container>
</template>
<script>
import CustomTextInput from "@/components/inputs/custom-text-input/CustomTextInput.vue";
import * as D from "@/duck";

export default {
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CustomTextInput: CustomTextInput,
  },
  data: function () {
    return {
      acessories: D.constants.ACESSORIES,
      selected: "Sim",
      placeholder: "Tipo do acessório",
      open: false,
      haveNF: null,
      isVisible: false,
      message:
        "Para prosseguir com a extensão de ciclocomputador é obrigatório possuir a nota fiscal do mesmo, não è necessário apresentar a nota neste momento, somente em caso de sinistro.",
      receivedConstant: null,
      fields: {
        brand: "",
        nf: "",
        value: "",
        isFormValid: false,
      },
      showErrors: {
        brand: false,
        nf: false,
        value: false,
      },
    };
  },
  methods: {
    handleClick() {
      this.validateField("brand");
      this.validateField("value");

      if (this.haveNF === true && this.fields.isFormValid === true) {
        this.fields.nf = this.haveNF;
        this.emitDetailAcessoriesData();
        this.clickHandler();
      }
    },
    checkFormValidity() {
      this.$refs.observer.validate().then((isValid) => {
        this.fields.isFormValid = isValid;
      });
    },
    getCurrencyMask(value) {
      if (value && value.length <= 6) {
        return "R$ ###";
      } else if (value && value.length <= 8) {
        return "R$ #.###";
      } else if (value && value.length <= 9) {
        return "R$ ##.###";
      } else if (value && value.length <= 11) {
        return "R$ #.###.###";
      } else if (value && value.length <= 12) {
        return "R$ ##.###.###";
      } else {
        return "R$ ###.###.###";
      }
    },
    validateField(field) {
      this.$refs.observer.validate(field).then(() => {
        if (field === "brand" && this.showErrors.brand === false) {
          this.showErrors[field] = false;
        } else {
          this.showErrors[field] = true;
        }
      });
    },
    handleInput(event) {
      const rawValue = event.target.value.replace(/[^0-9,]/g, "");
      const formattedValue = parseFloat(rawValue).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      this.fields.value = formattedValue;
    },
    emitDetailAcessoriesData() {
      this.$emit("detailAcessoriesData", this.fields);
    },

    openDrawer() {
      this.open = true;
    },
    closeDrawer() {
      this.open = false;
    },
    initializeFields() {
      if (this.data) {
        this.fields.brand = this.data.brand;
        this.fields.value = this.data.value;
        this.fields.nf = this.data.nf;
        this.haveNF = this.data.nf;
      }
    },
  },
  watch: {
    "fields.brand"() {
      this.checkFormValidity();
    },
    "fields.value"() {
      this.checkFormValidity();
    },
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
  },
};
</script>
<style scoped>
.title-ask {
    margin-left: 20px;
    font-size: 16px;
    margin-top: 30px;
    line-height: 24px;
    color: #5db247;
    margin-bottom: 10px;
}
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.continue-button {
  background-color: #5db247;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 30px;
  max-width: 120px;
  position: absolute;
  bottom: 1%;
  right: 2%;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter, .slide-leave-to /* Início e fim da animação */ {
  transform: translateX(-100%);
}
.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  
}
.info-icon {
  color: #f0a49e; /* Cor padrão */
}

.icon {
  border-color: #f0a49e; /* Cor padrão */
  padding: 4px;
}
.row-text {
  text-align: left;
}

.error-msg {
  font-size: 14px;
  line-height: 20px;
  color: #f70000;
}
.box-info {
  display: flex;
  align-items: center;
  background-color: #f3f3f3ea; /* Fundo cinza claro */
  border-radius: 4px; /* Borda arredondada */
  color: #78849e;
  font-size: 12px;
  padding: 10px; /* Espaçamento interno */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  margin: 10px; /* Margem externa */
}
.row-btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 25px;
}

.btn {
  background-color: rgb(250, 248, 248);
  color: white;
  font-size: 12px;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
  margin: 8px;
  border-radius: solid 1px #5db247;
  box-shadow: none;
  outline: none;
}
.attention {
  color: #f0a49e;
  font-weight: bold;
}
.btn.selected {
  font-size: 12px;
  background-color: #5db247; /* Fundo verde quando selecionado */
  color: white; /* Texto branco quando selecionado */
  border-radius: solid 1px #5db247;
  box-shadow: none;
  outline: none;
}

.btn:nth-child(1):not(.selected) {
  font-size: 12px;
  color: #5db247; /* Texto verde para o primeiro botão */
}

.btn:nth-child(2):not(.selected) {
  font-size: 12px;
  color: #8f9aae;
}

.ask-text {
  font-size: 16px;
  line-height: 24px;
  color: #5db247;
}

.title-text {
  font-size: 23px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}
.title-description {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #919ab0;
}
</style>
